<template>
  <ul class="pagination">
    <li v-if="isFirstPage" class="page-item disabled text-link">First</li>
    <li v-else class="page-item"><a class="text-link" v-on:click="showPage(1)">First</a></li>
    <li v-for="p in maxDisplayPage" class="page-item" :key="p">
        <a v-if="p+pageFrom==currentPage" class="page-active" v-on:click="showPage(p+pageFrom)">{{p+pageFrom}}</a>
        <a v-else class="page-link" v-on:click="showPage(p+pageFrom)">{{p+pageFrom}}</a>
    </li>
    <li v-if="currentPage>=totalPage" class="page-item disabled text-link">Last</li>
    <li v-else class="page-item"><a class="text-link" v-on:click="showPage(totalPage)">Last</a></li>
    <select 
      @change="changePageSize"
      v-model="settingPageSize"
      class="page-setting">
      <option value="5">5/page</option>
      <option value="20">20/page</option>
      <option value="30">30/page</option>
    </select>
  </ul>
</template>

<script>
export default {
  name: 'ReportPagination',
  props: {
    totalPage: {
      type: Number,
      require: true,
    },
    currentPage: {
      currentPage: Number,
      require: true,
    },
    pageSize: {
      pageSize: Number,
      require: true,
    }
  },
  computed: {
    isFirstPage() {
      return this.currentPage == 1
    },
    isLastPage() {
      return this.currentPage == this.totalPage
    },
    pageFrom() {
      const from = this.currentPage - 3 < this.totalPage - 5 ? (this.currentPage - 3) : this.totalPage - 5
      return from > 0 ? from : 0
    },
    maxDisplayPage() {
      return this.totalPage > 5 ? 5 : this.totalPage
    }
  },
  data() {
    return {
      settingPageSize: 5,
    }
  },
  mounted() {
    this.settingPageSize = this.pageSize
  },
  methods: {   
    showPage(page) {
      this.$emit('changePage', page)
    },
    changePageSize() {
      this.$emit('changePageSize', this.settingPageSize)
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination {
  margin: 10px 0px;
  display: flex;
  justify-content: center;
  .disabled {
    cursor: auto;
    color: gray;
  }
  .page-item {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 3px;
    cursor: pointer;
  }
  .page-active {
    cursor: auto;
  }
  .page-link {
    border: 1px solid #E8E9EC;
    height: 25px;
    width: 25px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .text-link {
    border: 1px solid #E8E9EC;
    height: 25px;
    width: 50px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .page-active {
    padding: 0 5px;
  }

  .page-setting {
    font-size: 12px;
    border: 1px solid #E8E9EC;
    margin-left: 10px;
    padding: 5px;
  }
}
</style>

