<template>
  <div class="attention-container">
    <div class="h1">注目すべき生徒</div>
    <div class="tables">
      <div class="table table-left">
        <div class="title">
          受験回数ランキング
        </div>
        <table>
          <thead>
            <tr>
              <th>名前</th>
              <th>順位</th>
              <th>回数</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(r, i) in timesRanking" @click="displayDetail(r.id)" :key="i">
              <td>
                <div class="name">{{ r.name }}</div>
                <div class="cls">{{ r.number }}</div>
              </td>
              <td>
                <span
                  class="rank-label"
                  v-if="top3Page(timesRankingPage.currentPage, i)">{{ rank(i) }}</span>
              </td>
              <td>{{ r.value }}</td>
            </tr>
          </tbody>
        </table>
        <report-table-pagination
          :totalPage="timesRankingPage.totalPage"
          :currentPage="timesRankingPage.currentPage"
          :pageSize="timesRankingPage.pageSize"
          @changePage="changeTimeRankingPage"
          @changePageSize="changeTimeRankingPageSize"
          />
      </div>
      <div class="table table-right">
        <div class="title">
          進捗度ランキング
        </div>
        <table>
          <thead>
            <tr>
              <th>名前</th>
              <th>順位</th>
              <th>進捗率</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(r, i) in achieveRanking" @click="displayDetail(r.id)" :key="i">
              <td>
                <div class="name">{{ r.name }}</div>
                <div class="cls">{{ r.number }}</div>
              </td>
              <td>
                <span
                  class="rank-label"
                  v-if="top3Page(achieveRankingPage.currentPage, i)">{{ rank(i) }}</span>
              </td>
              <td>{{ r.value }}</td>
            </tr>
          </tbody>
        </table>
        <report-table-pagination
          :totalPage="achieveRankingPage.totalPage"
          :currentPage="achieveRankingPage.currentPage"
          :pageSize="achieveRankingPage.pageSize"
          @changePage="changeAchieveRankingPage"
          @changePageSize="changeAchieveRankingPageSize"
          />
      </div>
    </div>
  </div>
  <div class="attention-container">
    <div class="h1">指導すべき生徒</div>
    <div class="tables">
      <div class="table table-left">
        <div class="title">
          未受験・受験回数が少ない生徒ランキング
        </div>
        <table>
          <thead>
            <tr>
              <th>名前</th>
              <th>順位</th>
              <th>回数</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(r, i) in reverseTimesRanking" @click="displayDetail(r.id)" :key="i">
              <td>
                <div class="name">{{ r.name }}</div>
                <div class="cls">{{ r.number }}</div>
              </td>
              <td>
                <span
                  class="rank-label-gray"
                  v-if="top3Page(reverseTimesRankingPage.currentPage, i)">{{ rank(i) }}</span>
              </td>
              <td>{{ r.value }}</td>
            </tr>
          </tbody>
        </table>
        <report-table-pagination
          :totalPage="reverseTimesRankingPage.totalPage"
          :currentPage="reverseTimesRankingPage.currentPage"
          :pageSize="reverseTimesRankingPage.pageSize"
          @changePage="changeReverseTimeRankingPage"
          @changePageSize="changeReverseTimeRankingPageSize"
          />
      </div>
      <div class="table table-right">
        <div class="title">
          進捗度が少ない生徒ランキング
        </div>
        <table>
          <thead>
            <tr>
              <th>名前</th>
              <th>順位</th>
              <th>進捗率</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(r, i) in reverseAchieveRanking" @click="displayDetail(r.id)" :key="i">
              <td>
                <div class="name">{{ r.name }}</div>
                <div class="cls">{{ r.number }}</div>
              </td>
              <td>
                <span
                  class="rank-label-gray"
                  v-if="top3Page(reverseAchieveRankingPage.currentPage, i)">{{ rank(i) }}</span>
              </td>
              <td>{{ r.value }}</td>
            </tr>
          </tbody>
        </table>
        <report-table-pagination
          :totalPage="reverseAchieveRankingPage.totalPage"
          :currentPage="reverseAchieveRankingPage.currentPage"
          :pageSize="reverseAchieveRankingPage.pageSize"
          @changePage="changeReverseAchieveRankingPage"
          @changePageSize="changeReverseAchieveRankingPageSize"
          />
      </div>
    </div>
  </div>
</template>

<script>
import ReportTablePagination from '../../components/molecule/ReportTablePagination.vue'

export default {
  name: 'AttentionStudents',
  components: {
    ReportTablePagination,
  },
  props: {
    start: {
      type: String,
      require: true,
    },
    end: {
      type: String,
      require: true,
    },
    userIds: {
      type: Array,
      require: true,
    }
  },
  mounted() {
    this.getDatas()
  },
  data() {
    return {
      timesRanking: [],
      achieveRanking: [],
      reverseTimesRanking: [],
      reverseAchieveRanking: [],
      timesRankingPage: {
        pageSize: 5,
        totalPage: 1,
        currentPage: 1,
      },
      achieveRankingPage: {
        pageSize: 5,
        totalPage: 1,
        currentPage: 1,
      },
      reverseTimesRankingPage: {
        pageSize: 5,
        totalPage: 1,
        currentPage: 1,
      },
      reverseAchieveRankingPage: {
        pageSize: 5,
        totalPage: 1,
        currentPage: 1,
      },
    }
  },
  computed: {
  },
  methods: {
    getDatas() {
      this.getRankingData()
      this.getAchieveRankingData()
      this.getReverseRankingData()
      this.getReverseAchieveRankingData()
    },
    refresh() {
      this.getDatas()
    },
    displayDetail(userId) {
      console.log('userId:' + userId)
      const routeData = this.$router.resolve(`/report?userId=${userId}&start=${this.start}&end=${this.end}`)
      window.open(routeData.href, '_blank')
    },
    top3Page(currentPage, index) {
      return currentPage == 1 && index < 3
    },
    rank(index) {
      switch(index) {
        case 0:
          return '1st'
        case 1:
          return '2nd'
        case 2:
          return '3rd'
        default: return ''
      }
    },
    getRankingData() {
      this.$api.post(
        '/report/students/times/ranking', {
          userIds: this.userIds,
          pageSize: this.timesRankingPage.pageSize,
          pageNo: this.timesRankingPage.currentPage,
          start: this.start,
          end: this.end,
          direction: 2,
      }).then(res => {
        this.timesRanking = res.data.students
        this.timesRankingPage.totalPage = res.data.totalPage
      })
    },
    getAchieveRankingData() {
      this.$api.post(
        '/report/students/achieve/ranking', {
          userIds: this.userIds,
          pageSize: this.timesRankingPage.pageSize,
          pageNo: this.timesRankingPage.currentPage,
          start: this.start,
          end: this.end,
          direction: 2,
      }).then(res => {
        this.achieveRanking = res.data.students
        this.achieveRankingPage.totalPage = res.data.totalPage
      })
    },
    getReverseRankingData() {
      this.$api.post(
        '/report/students/times/ranking', {
          userIds: this.userIds,
          pageSize: this.reverseTimesRankingPage.pageSize,
          pageNo: this.reverseTimesRankingPage.currentPage,
          start: this.start,
          end: this.end,
          direction: 1,
      }).then(res => {
        this.reverseTimesRanking = res.data.students
        this.reverseTimesRankingPage.totalPage = res.data.totalPage
      })
    },
    getReverseAchieveRankingData() {
      this.$api.post(
        '/report/students/achieve/ranking', {
          userIds: this.userIds,
          pageSize: this.reverseAchieveRankingPage.pageSize,
          pageNo: this.reverseAchieveRankingPage.currentPage,
          start: this.start,
          end: this.end,
          direction: 1,
      }).then(res => {
        this.reverseAchieveRanking = res.data.students
        this.reverseAchieveRankingPage.totalPage = res.data.totalPage
      })
    },
    changeTimeRankingPage(page) {
      this.timesRankingPage.currentPage = page
      this.getRankingData()
    },
    changeTimeRankingPageSize(size) {
      this.timesRankingPage.pageSize = size
      this.getRankingData()
    },
    changeAchieveRankingPage(page) {
      this.timesRankingPage.currentPage = page
      this.getAchieveRankingData()
    },
    changeAchieveRankingPageSize(size) {
      this.timesRankingPage.pageSize = size
      this.getAchieveRankingData()
    },
    changeReverseTimeRankingPage(page) {
      this.reverseTimesRankingPage.currentPage = page
      this.getReverseRankingData()
    },
    changeReverseTimeRankingPageSize(size) {
      this.reverseTimesRankingPage.pageSize = size
      this.getReverseRankingData()
    },
    changeReverseAchieveRankingPage(page) {
      this.reverseAchieveRankingPage.currentPage = page
      this.getReverseAchieveRankingData()
    },
    changeReverseAchieveRankingPageSize(size) {
      this.reverseAchieveRankingPage.pageSize = size
      this.getReverseAchieveRankingData()
    },
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
.attention-container {
  width: 90%;
  margin: auto;
  margin-top: 20px;
  .h1 {
    text-align: left;
    font-size: 18px;
  }
}
.tables {
  display: flex;
  .table {
    box-shadow: 0px 2px 6px #00000066;
    .title {
      height: 40px;
      text-align: left;
      color: #4D4F5C;
      text-indent: 10px;
      display: flex;
      align-items: center;
      font-size: 13px;
    }
    table {
      width: 100%;
      border-collapse: collapse;
      thead {
        background: #F5F6FA 0% 0% no-repeat padding-box;
        height: 30px;
        align-items: center;
        text-indent: 10px;
        color: #A3A6B4;
        th:nth-child(1) {
          width: 50%;
        }
        th:nth-child(2) {
          width: 30%;
        }
        th:nth-child(3) {
          width: 20%;
        }
      }
      tbody {
        tr {
          height: 50px;
          &:hover {
            background: rgb(209, 235, 250);
          }
          td:nth-child(1) {
            text-align: left;
            text-indent: 35px;
          }
          td {
            border-bottom: 1px solid #E8E9EC;
            .name {
              font-size: 12px;
            }
            .cls {
              font-size: 13px;
              text-indent: 60px;
            }
            .rank-label {
              background: #e1faed;
              color: #717c77;
              font-weight: bold;
              padding: 2px 15px;
              font-size: 13px;
              border-radius: 15px;
            }
            .rank-label-gray {
              background: #fce0e8;
              color: #a59298;
              font-weight: bold;
              padding: 2px 15px;
              font-size: 13px;
              border-radius: 15px;
            }
          }
        }
      }
    }
  }
  .table-left {
    flex: 5;
    margin: 10px 10px 20px 10px;
  }
  .table-right {
    flex: 3;
    margin: 10px 10px 20px 10px;
  }
}
</style>