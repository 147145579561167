<template>
  <div class="range-calendar mt-2">
    <div>
      表示期間を選択してください
      <span
        @click="today"
        class="preset-range">
        本日
      </span>
      <span
        @click="yesterday"
        class="preset-range">
        昨日
      </span>
      <span
        @click="lastweek"
        class="preset-range">
        先週
      </span>
    </div>
    <v-date-picker
      :locale="{ id: 'en',masks: { weekdays: 'WW' } }"
      :columns="2"
      v-model="range"
      :is-range="true"
      ref="calendar"
      mode="date" />
  </div>

  <!--sample chart-->
  <div class="charts">
    <highcharts class="hc" :options="chartOptions" ref="chart"></highcharts>
  </div>

  <!--sample chart-->
  <div class="charts">
    <highcharts class="hc" :options="barChart" ref="chart"></highcharts>
  </div>
  <div @click="changeStudents">
    change students
  </div>
  <div @click="refreshTable">
    refresh table
  </div>
  <AttentionStudents
    ref="attentionStudents"
    start="2023-01-01"
    end="2023-06-25"
    :userIds="selectUserIds"
    @refresh="refreshChild"
    />
</template>

<script>
// 得意lessonの判断基準
// 複数人ある場合、aさん得意、bさん得意はどうやって集計するのか?
// 得意lessonが期間と関係あるでしょうか？
//
// https://jsfiddle.net/xajub20o/
// https://vcalendar.io/calendar/dates.html
// https://www.highcharts.com/demo
import 'v-calendar/dist/style.css'

import Highcharts from 'highcharts'
import exportingInit from 'highcharts/modules/exporting'
import AttentionStudents from './AttentionStudents.vue'

exportingInit(Highcharts)

export default {
  name: 'ReportSample',
  components: {
    AttentionStudents,
  },
  data() {
    return {
      selectedGrade: null,
      options: [
        '1年生',
        '2年生',
        '3年生',
      ],
      date: new Date(),
      range: {
        start: new Date(),
        end: new Date(),
      },
      chartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          text: ''
        },
        plotOptions: {
          column: {
            color: '#a3a1fb',
            borderRadius: 5
          }
        },
        legend: {
          enabled: false,
        },
        exporting: {
          buttons: {
            contextButton: {
              enabled: false
            }
          }
        },
        yAxis: {
          title: {
            text: null
          },
          gridLineColor: '#eaf0f4'
        },
        xAxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul']
        },
        series: [
          {
            name: '受験回数',
            data: [6, 2, 3, 1, 4, 6, 5],
          },
        ],
      },
      barChart: {
        chart: {
          type: 'bar',
          height: 300,
        },
        title: {
          text: null,
        },
        plotOptions: {
          bar: {
            color: '#3b86ff',
            borderRadius: 3,
            pointWidth: 15,
            dataLabels: {
              enabled: true,
              formatter: function() {
                return (this.y * 100).toFixed(2) + "%"
              }
            }
          }
        },
        legend: {
          enabled: false,
        },
        exporting: {
          buttons: {
            contextButton: {
              enabled: false
            }
          }
        },
        xAxis: {
          categories: ['1年1(A)組', '1年1(B)組', '1年1(C)組', '1年1(D)組', '1年1(E)組', '1年1(F)組', '1年1(G)組', '1年1(H)組', '1年1(I)組', '1年1(J)組'],
          title: {
            text: null
          },
        },
        yAxis: {
          gridLineColor: 'white',
          labels: {
            enabled: false,
            // formatter: function(target) {
            //   return target.value + '%'; // 将纵轴标签的值转换为百分比格式
            // }
          },
          title: {
            text: null
          },
          max: 1,
        },
        series: [{
            name: '',
            type: 'bar',
            data: [0.1, 0.2124, 0.3, 0.5, 0.7, 0.88, 0.99, 1, 0.43, 0.35]
        }],
        tooltip: {
          formatter: function() {
            console.log(this.x)
            return "<b>" + this.x + "</b><br>" + (this.y * 100).toFixed(2) + "%"
          }
        },
      },
      selectUserIds: [1, 3156, 3216, 3587],
    }
  },
  computed: {
    rangeStart() {
      return this.dateFormat(this.range.start)
    },
    rangeEnd() {
      return this.dateFormat(this.range.start)
    },
  },
  mounted() {
    const today = new Date()
    const lastSunday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay())
    const lastMonday = new Date(lastSunday.getFullYear(), lastSunday.getMonth(), lastSunday.getDate() + 6)
    this.range = {
      start: lastSunday,
      end: lastMonday
    }
  },
  methods: {
    refreshChild() {
      console.log("refreshChild")
    },
    refreshTable() {
      this.$refs.attentionStudents.refresh()
    },
    changeStudents() {
      this.selectUserIds = [1,2,3,4,5]
    },
    dateFormat(date) {
      const year = date.getFullYear()
      const month = ('0' + (date.getMonth() + 1)).slice(-2)
      const day = ('0' + date.getDate()).slice(-2)
      return `${year}-${month}-${day}`
    },
    backToMenu() {
      this.$router.push('/manager/menu')
    },
    submit(id) {
      console.log('id:' + id)
    },
    today() {
      console.log('today')
      this.range = {
        start: new Date(),
        end: new Date()
      }
      const calendar = this.$refs.calendar
      calendar.move(new Date())
    },
    yesterday() {
      const today = new Date()
      const yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
      this.range = {
        start: yesterday,
        end: yesterday
      }
      const calendar = this.$refs.calendar
      calendar.move(yesterday)
    },
    lastweek() {
      const today = new Date()
      const lastSunday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 7)
      const lastMonday = new Date(lastSunday.getFullYear(), lastSunday.getMonth(), lastSunday.getDate() + 6)
      this.range = {
        start: lastSunday,
        end: lastMonday
      }
      const calendar = this.$refs.calendar
      calendar.move(lastSunday)
    }
  }
}
</script>

><style lang="scss">
.highcharts-credits {
  display: none;
}
</style>

<style lang="scss" scoped>
.range-calendar {
  border: 1px solid;
  margin: auto;
  width: 600px;
  padding: 10px;
  margin-top: 20px;
}

.vc-container {
  border: none;
}

.charts {
  margin: auto;
  margin-top: 30px;
  width: 70%;
}
</style>

